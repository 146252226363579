import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../../header/header";
import { UserLayoutWrapper } from "./user-layout.style";
import Footer from "_components/organisms/footer/footer";
import { Cookies, CookiesConfig } from "_components/atoms";

const UserLayout = () => {
  const location = useLocation();
  const [isCookiesConfigOpen, setIsCookiesConfigOpen] = useState(false);

  const openCookiesConfig = () => {
    setIsCookiesConfigOpen(true);
  };

  const closeCookiesConfig = () => {
    setIsCookiesConfigOpen(false);
  };
  useEffect(() => {
    // Scroll to top on location change
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <UserLayoutWrapper>

      <Cookies openCookiesConfig={openCookiesConfig} />
      {isCookiesConfigOpen && <CookiesConfig closeCookiesConfig={closeCookiesConfig} />}
        <Header />
        <Outlet />
        <Footer />
    </UserLayoutWrapper>
  );
};

export default UserLayout;
