import React, { useState } from "react";
import { useLanguage } from "_context/languageContext";
import { useTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownButton,
  DropdownMenu,
} from "./language-selector.style";
import flagPT from "_assets/img/flags/pt.svg";
import flagEN from "_assets/img/flags/uk.svg";
import flagES from "_assets/img/flags/es.svg";
import flagDE from "_assets/img/flags/de.svg";
import flagFR from "_assets/img/flags/fr.svg";
import flagUA from "_assets/img/flags/ua.svg";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { changeLanguage: changeLang } = useLanguage();

  const changeLanguage = (language) => {
    changeLang(language); // Call the changeLanguage function from the context
    setIsOpen(false);
  };

  const getLanguageCode = (language) => {
    switch (language) {
      case "pt":
        return (
          <>
            {/* <img src={flagPT} alt="Português" /> */}
            <p>PT</p>
          </>
        );
      case "en":
        return (
          <>
            {/* <img src={flagEN} alt="English" /> */}
            <p>EN</p>
          </>
        );
      case "es":
        return (
          <>
            {/* <img src={flagES} alt="Español" /> */}
            <p>ES</p>
          </>
        );
      case "fr":
        return (
          <>
            {/* <img src={flagFR} alt="Français" /> */}
            <p>FR</p>
          </>
        );
      case "de":
        return (
          <>
            {/* <img src={flagDE} alt="Deutsch" /> */}
            <p>DE</p>
          </>
        );
      case "ua":
        return (
          <>
            {/* <img src={flagUA} alt="українська" /> */}
            <p>UA</p>
          </>
        );
      default:
        return (
          <>
            {/* <img src={flagPT} alt="Português" /> */}
            <p>PT</p>
          </>
        );
    }
  };

  return (
    <Dropdown>
      <DropdownButton onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        {getLanguageCode(i18n.language)}
      </DropdownButton>
      <DropdownMenu className="dropmenu" isOpen={isOpen}>
        <div onClick={() => changeLanguage("pt")}>
          <img src={flagPT} alt="Portuguese" />
          <p>PT</p>
        </div>
        <div onClick={() => changeLanguage("en")}>
          <img src={flagEN} alt="English" />
          <p>EN</p>
        </div>
        <div onClick={() => changeLanguage("es")}>
          <img src={flagES} alt="Español" />
          <p>ES</p>
        </div>
        <div onClick={() => changeLanguage("fr")}>
          <img src={flagFR} alt="Français" />
          <p>FR</p>
        </div>
        <div onClick={() => changeLanguage("de")}>
          <img src={flagDE} alt="Deutsch" />
          <p>DE</p>
        </div>
        <div onClick={() => changeLanguage("ua")}>
          <img src={flagUA} alt="українська" />
          <p>UA</p>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageSelector;