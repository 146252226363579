import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalText,
  Switch,
} from "./cookies-config.style";
import { Link } from "react-router-dom";
import { Button } from "_components/atoms";

const CookiesConfig = ({ closeCookiesConfig }) => {
  const [performanceCookies, setPerformanceCookies] = useState(true);
  const [functionalityCookies, setFunctionalityCookies] = useState(false);
  const [advertisingCookies, setAdvertisingCookies] = useState(false);

  useEffect(() => {
    // Bloqueia o scroll ao montar o componente
    document.body.style.overflow = 'hidden';

    // Carrega as preferências de cookies do localStorage
    const savedPreferences = JSON.parse(localStorage.getItem('cookiePreferences'));
    if (savedPreferences) {
      setPerformanceCookies(savedPreferences.performanceCookies);
      setFunctionalityCookies(savedPreferences.functionalityCookies);
      setAdvertisingCookies(savedPreferences.advertisingCookies);
    }

    return () => {
      // Restaura o scroll ao desmontar o componente
      document.body.style.overflow = 'auto';
    };
  }, []);

  const savePreferences = (preferences) => {
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
  };

  const handleSavePreferences = () => {
    const preferences = {
      performanceCookies,
      functionalityCookies,
      advertisingCookies,
    };
    savePreferences(preferences);
    closeCookiesConfig();
    document.body.style.overflow = 'auto'; // Restaura o scroll
  };

  const handleRejectAll = () => {
    setPerformanceCookies(false);
    setFunctionalityCookies(false);
    setAdvertisingCookies(false);
    savePreferences({
      performanceCookies: false,
      functionalityCookies: false,
      advertisingCookies: false,
    });
    closeCookiesConfig();
    document.body.style.overflow = 'auto'; // Restaura o scroll
  };

  const handleAcceptAll = () => {
    setPerformanceCookies(true);
    setFunctionalityCookies(true);
    setAdvertisingCookies(true);
    savePreferences({
      performanceCookies: true,
      functionalityCookies: true,
      advertisingCookies: true,
    });
    closeCookiesConfig();
    document.body.style.overflow = 'auto'; // Restaura o scroll
  };

  return (
    <Modal>
      <ModalContent>
        <ModalHeader>
          <h2>Configurações de Cookies</h2>
        </ModalHeader>
        <ModalBody>
          <ModalText>
            <p>Informações importantes:</p>
            <p>
              Escolha quais tipos de cookies você permite que usemos no nosso
              site. Sua escolha nos ajudará a oferecer uma experiência
              personalizada e otimizada para você.
            </p>
          </ModalText>
          <div className="cookie-section">
            <div className="cookie-text-switch">
              <h3>Cookies Necessários</h3>
              <p>
                Essenciais para o funcionamento do site. Eles garantem que o
                site funcione corretamente e não podem ser desativados em nossos
                sistemas.
              </p>
            </div>
            <div className="active-wrapper">
              <div className="text-button">
                <p className="always-active">Sempre ativos</p>
                <Switch className="active" checked={true} disabled />
              </div>
            </div>
          </div>
          <div className="cookie-section">
            <div className="cookie-text-switch">
              <h3>Cookies de Desempenho</h3>
              <p>
                Nos ajudam a entender como os visitantes interagem com o site,
                permitindo melhorar seu desempenho e usabilidade.
              </p>
            </div>
            <Switch
              checked={performanceCookies}
              onChange={() => setPerformanceCookies(!performanceCookies)}
            />
          </div>
          <div className="cookie-section">
            <div className="cookie-text-switch">
              <h3>Cookies de Funcionalidade</h3>
              <p>
                Permitem que o site lembre-se de suas escolhas (como seu nome de
                usuário, idioma ou a região em que você está) e ofereçam
                funcionalidades avançadas e personalizadas.
              </p>
            </div>
            <Switch
              checked={functionalityCookies}
              onChange={() => setFunctionalityCookies(!functionalityCookies)}
            />
          </div>
          <div className="cookie-section">
            <div className="cookie-text-switch">
              <h3>Cookies de Publicidade</h3>
              <p>
                Usados para coletar informações sobre seus hábitos de navegação
                para que possamos oferecer anúncios relevantes aos seus
                interesses.
              </p>
            </div>
            <Switch
              checked={advertisingCookies}
              onChange={() => setAdvertisingCookies(!advertisingCookies)}
            />
          </div>
          <p>
            Para mais detalhes sobre como usamos cookies e como você pode
            alterar suas configurações a qualquer momento, por favor, consulte
            nossa <Link to="/dados-pessoais-privacidade" onClick={() => { closeCookiesConfig(); document.body.style.overflow = 'auto'; }}>Política de Cookies</Link>.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button className="not-active" onClick={handleSavePreferences}>
            Salvar Preferências
          </Button>
          <Button className="not-active" onClick={handleRejectAll}>
            Rejeitar Todos
          </Button>
          <Button className="button" onClick={handleAcceptAll}>
            Aceitar Todos
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CookiesConfig;