import React from "react";
import { Link } from "react-router-dom";
import { LogoContainer } from "./logo.style";

const Logo = ({ isScrolled }) => {
  return (
    <LogoContainer isScrolled={isScrolled}>
      <Link to="/" className="logo">
        <img
          src={require("_assets/img/logowb.png")}
          alt="Médicos na Manga Logo"
          className="brand"
        />
      </Link>
    </LogoContainer>
  );
};

export default Logo;
