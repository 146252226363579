import { screens } from "_theme/screens";
import styled from "styled-components";

const LogoContainer = styled.div`
  display: flex;
  height: ${({ isScrolled }) => (isScrolled ? '2rem' : '3rem')};
  transition: height 0.3s ease-in-out;

  ${screens.lg} {
      height: 100%;
      width: 0;
    }

  .logo{
    display: flex;
    height: 100%;
  }
`;
export { LogoContainer };
