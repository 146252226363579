import { createGlobalStyle } from "styled-components";
import { colors } from "./colors";
import { fontImport } from "./typography";
import {
  SearchbarContainerStyles,
  formContainerStyles,
  formControlStyles,
} from "./common";
import { screens } from "./screens";

export const GlobalStyle = createGlobalStyle`

${fontImport}

 * {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    /* background: ${({ theme }) => theme.body}; */
    color: ${({ theme }) => theme.text};
    transition: all 0.2s ease-in, color 0.2s ease-in;
    scroll-behavior: smooth;
  }

  body{
    margin: 0 auto; 

  }
  
  h1,h2,h3,h4,h5,h6,p{
    margin: 0;
  }

  h1{
    font-size: 1.5rem;
  }

ul{
    list-style: none;
    margin: 0;
    padding: 0;    
}

a{
  text-decoration: none;
  margin: none;
  transition: all .2s;
} 

a:hover{
  cursor: pointer;
}

.container{
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh */
} 

::placeholder{
  font-size: 13px;
}

::-webkit-scrollbar {
  /* width: 5px; */
  /* height: 5px; */
  /* overflow-x: hidden; */
  
  &:hover {
    width: 10px; /* Aumenta a largura ao passar o mouse */
    height: 10px; /* Aumenta a altura para barras horizontais ao passar o mouse */
}
}



::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
}

::-webkit-scrollbar-thumb {
  background: ${colors.light_border};
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: ${colors.dark_border};
}

::-webkit-scrollbar-corner { 
  background: transparent;
 }

::placeholder{
  color: ${({ theme }) => theme.text};
}

${(props) => formControlStyles(props.theme)};
${(props) => formContainerStyles(props.theme)};
${(props) => SearchbarContainerStyles(props.theme)};

    `;
