import React, { useContext, useEffect, useState } from "react";
import { Nav } from "_components/molecules";
import { Navbar } from "./header.style";
import { ThemeContext } from "_context/themeContext";
import { Cookies, TopPage } from "_components/atoms";

const Header = () => {
  const { isDarkTheme, toggleTheme } = useContext(ThemeContext);
  const [scrollY, setScrollY] = useState(0);
  const [opacity, setOpacity] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollY(position);
    setOpacity(Math.min(1, position / 100)); // Aumenta a opacidade ao fazer scroll down
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Navbar opacity={opacity}>
      <TopPage />
            {/* <Cookies/> */}
      <Nav />
    </Navbar>
  );
};

export default Header;
