import React, { useState, useEffect } from 'react';
import { CookiesWrapper, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, ModalText, ModalButton } from './cookies.style';
import { Link } from 'react-router-dom';

const Cookies = ({ openCookiesConfig }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Bloqueia o scroll ao montar o componente
    document.body.style.overflow = 'hidden';

    // Verifica se as preferências de cookies já estão salvas no localStorage
    const savedPreferences = localStorage.getItem('cookiePreferences');
    if (!savedPreferences) {
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, 2000); // 2 segundos

      return () => {
        clearTimeout(timer); // Limpa o timer se o componente for desmontado
      };
    } else {
      document.body.style.overflow = 'auto'; // Restaura o scroll se as preferências já existirem
    }
  }, []);

  const handleClose = () => {
    // Salva as preferências de cookies necessários e de desempenho
    const preferences = {
      performanceCookies: true,
      functionalityCookies: false,
      advertisingCookies: false,
    };
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));

    setIsOpen(false);
    document.body.style.overflow = 'auto'; // Restaura o scroll
  };

  const handleOpenConfig = () => {
    handleClose();
    openCookiesConfig();
  };

  return (
    <>
      {isOpen && (
        <Modal>
          <ModalContent>
            <ModalBody>
              <ModalText>
                <p>Utilizamos cookies para melhorar a sua experiência neste website. Ao navegar está a consentir a utilização.</p>
                <p className='bold'>
                  Você pode gerenciar suas preferências de cookies a qualquer momento nas <Link to="#" onClick={handleOpenConfig}>Configurações de Cookies.</Link>
                </p>
              </ModalText>
              <ModalButton>
                <Button onClick={handleClose}>Aceitar Cookies</Button>
              </ModalButton>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default Cookies;