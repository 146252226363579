import React from "react";
import { Link } from "react-router-dom";
import { CopyrightWrapper } from "./copyright.style";
import { useTranslation } from "react-i18next";
import { ReactComponent as ERS } from "_assets/img/ers.svg";

import SocialLinks from "../social-links/social-links";
import Button from "../buttons/button/button";

const Copyright = () => {
  const navigation = [
    // { name: "Footer.cookies", href: "/cookies" },
    { name: "Footer.terms", href: "/dados-pessoais-privacidade" },
    // { name: "Footer.rgpd", href: "/rgpd" },
  ];
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <CopyrightWrapper>
      <div className="production">
        <span>© Mínima - Médicos & Arte lda. {currentYear}</span>

        <div className="ers">
          <a
            className="ers-logo"
            href={`${process.env.PUBLIC_URL}/ers/Certificado_ERS_2024.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ERS /> <span>E165832</span>
          </a>
        </div>
      </div>
      <div className="navigation">
        {navigation.map((item) => (
          <Link className="linkstyle" key={item.name} to={item.href}>
            {t(item.name)}
          </Link>
        ))}
      </div>
    </CopyrightWrapper>
  );
};

export default Copyright;
