import styled from "styled-components";
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import background2 from "_assets/img/graphics/a.svg";
import banner from "_assets/img/banners/BANNER-Homepage-02.png";

export const CalculadoraWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 2rem auto; */
  margin: auto;
  width: 100%;
  max-width: 1920px;
  gap: 2rem;
  ${screens.lg} {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    gap: 0;
  }

  .content-container {
    flex-grow: 1;
height: 100%;
    width: 100%;
    aspect-ratio: 1/1;
    ${screens.lg} {
      /* height: 50%; */
      text-align: center;
      width: 100vw;
    }

    .mapa {
      height: 100%;
      width: 100%;
      /* margin: 0 2rem; */
      ${screens.lg} {
        /* width: 100%; */
        /* height: 300px; */
        height: 100%;
        width: 100%;
      }
    }

    &.data {
      /* width: 100%; */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* margin: 0 2rem; */


      ${screens.lg} {
        margin: 0;
        h2 {
          font-size: 16px;
        }
        padding: 1rem;
      }

      h2 {
        color: ${colors.primary_2};
        text-transform: uppercase;
      }

      input {
        width: 30%;
        margin: 2rem 0 2rem;
        font-size: 24px;
        padding: 0.5rem;
        text-align: left;
        font-weight: bold;

        ${screens.lg} {
          width: 40%;
          margin: 1rem 0;
          /* padding: 1rem ; */
        }
      }

      .post-code {
        margin-bottom: 1rem;
        p {
          display: flex;
          width: 100%;
        }
        select {
          width: 100%;
          text-align: left;
          padding: 0;
        }
      }

      .address-info {
        /* border: 1px solid red; */
        /* width: 60%; */
        margin-bottom: 2rem;
        p {
          margin: 0.5rem 0;
        }
        ${screens.lg} {
          margin-bottom: 1rem;
        }
      }
      .results {
        /* border: 1px solid red; */
        text-align: right;
        margin: 0 0 2rem;
        .professionals-available {
          color: ${colors.secondary};
        }
        .consultation-cost {
          font-weight: bold;
          color: ${colors.primary_2};
          text-align: start;

          span {
            font-size: 24px;
            font-weight: bold;
            color: ${colors.primary_2};
            ${screens.lg} {
              font-size: 18px;
            }
          }
        }
        p {
          margin: 1rem 0;
        }
      }
    }
  }
  .input-container {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
    input {
      padding: 0.5rem;
      font-size: medium;
      border: none;
      border-bottom: 2px solid ${colors.light_border};
      width: 100%;
      padding-left: 1rem;
      transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:focus {
        border-radius: 2px;
        outline: 2px solid #87bff2;
        box-shadow: 0 0 0 0.25rem #0f7fe540;
      }
    }
    input::placeholder,
    textarea::placeholder {
      color: inherit;
    }

    input.error::placeholder,
    textarea.error::placeholder {
      color: red;
    }
    .textarea-container {
      display: flex;
      max-width: 100%;

      textarea {
        background-image: url(${background2});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        resize: vertical;
        min-height: 5rem;
        max-height: 8rem;
        overflow: auto;
        padding: 0.5rem 1rem;
        font-size: medium;
        border: none;
        border-bottom: 2px solid ${colors.light_border};
        border-left: 2px solid ${colors.light_border};
        width: 100%;
        transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        /* background: ${({ theme }) => theme.form}; */

        &:focus {
          border-radius: 2px;
          outline: 2px solid #87bff2;
          box-shadow: 0 0 0 0.25rem #0f7fe540;
        }
      }
      ::placeholder {
        opacity: 0.5;
      }
    }
  }
  Button {
    width: 100%;
  }

  .price-red {
    color: red;
  }
`;
