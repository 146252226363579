import styled from "styled-components";
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import banner from "_assets/img/banners/BANNER-Homepage-02.png";
import background2 from "_assets/img/graphics/a.svg";

const ServicesWrapper = styled.div`
  background: ${({ theme }) => theme.background};
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  margin: 0 auto;
  background-image: url(${background2});
  background-size: cover;
  
  .services-banner {
    background-image: url(${banner});
    width: 100%;
    height: 13rem;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;

    /* margin-top: -4rem; */

    h1 {
      font-size: 4rem;
      color: ${colors.white};
      text-transform: uppercase;
      margin: 0 0 0 10%;
    }
  }

  .service-container {
    margin: 0 auto;
    background-position: center; // Centraliza a imagem de fundo
    ${screens.lg} {
      margin: 0;
    }
    p {
      padding-top: 1rem;
      text-align: justify;
      line-height: 1.5;
      text-transform: uppercase;
      font-weight: bold;
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(3, minmax(min-content, 2fr));
      gap: 10px; /* Space between rows and columns */
      padding: 48px 20px; /* Optional: Adds some padding around the grid */
      align-items: baseline;

   ${screens.lg} {
        grid-template-columns: repeat(2, 1fr); // Duas colunas em telas grandes
        gap: 10px; /* Espaço entre linhas e colunas */
        padding: 0;
        justify-items: center;
      }
    }
    a {
      width: min-content;
    }
    .grid-item {
      padding: 10px; /* Optional: Adds some padding inside each grid item */
      text-align: center; /* Optional: Centers the content inside the grid item */
   

      ${screens.lg} {
        margin: 0;
        font-size: smaller;
        img{
          width: 80px;
        }
      }

      p {
        text-align: center;
      }
    }

    ${screens.lg} {
      padding: 2rem;
    }
  }

  .info-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem 0;
    background: ${({ theme }) => theme.background_emphasis};

    ${screens.lg} {
      flex-direction: row;
      flex-wrap: nowrap;
      /* border: 1px solid red; */
      padding: 2rem 1rem;
    }

    .item {
      text-align: center;
      flex-basis: 200px;
      align-items: center;
      font-weight: 700;
      /* border: 1px solid blue; */

      display: flex;
      flex-direction: column;
      /* margin: 0; */

      p {
        font-weight: 400;
      }
      ${screens.lg} {
        /* height: 100%; */
        margin: 0;
        gap: 1rem;
        padding: 1rem;
      }
      img {
        /* border: 1px solid yellow; */
        ${screens.lg} {
          width: 100%;
          max-height: 60px;
        }
      }

      figcaption {
        font-size: smaller;
      }
    }
  }
  .middle-container {
    display: flex;
    padding: 0 9.625rem;
    ${screens.lg} {
      padding: 0;
    }

    .container-left,
    .container-right {
      flex: 1;
      /* max-height: 500px; */
    }

    .container-left {
      background-color: grey;
      background-size: cover;
      background-position: center;
      ${screens.lg} {
        display: none;
      }
    }

    .container-right {
      text-align: center;
      padding: 4rem 2rem;
      max-width: 600px;
      margin: 0 auto;
      ${screens.lg} {
        padding: 0;
      }
    }
  }

  .bottom-container {
    display: flex;
    padding: 0 9.625rem;
    ${screens.lg} {
      padding: 0;
    }

    .container-left,
    .container-right {
      flex: 1;
      /* max-height: 500px; */
    }

    .container-right {
      background-color: grey;
      background-size: cover;
      background-position: center;
      ${screens.lg} {
        display: none;
      }
    }

    .container-left {
      text-align: center;
      padding: 4rem 2rem;
      max-width: 600px;
      margin: 0 auto;
      ${screens.lg} {
        padding: 0;
      }
    }
  }

  .middle-container,
  .bottom-container {
    margin: 4rem 0;

    ${screens.lg} {
      margin: 0;
    }
  }
`;

export { ServicesWrapper };
