import React, { useState } from "react";
import { ContactsWrapper } from "./contacts.style";
import contacts from "_assets/img/svg/contactos.svg";
import { Button, ContactListWrapper, SocialLinks } from "_components/atoms";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Contacts = () => {
  const { t } = useTranslation();
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [descricao, setDescricao] = useState("");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isValidPhone = (phone) => {
    return /^\d{9,15}$/.test(phone);
  };

  const isValidName = (name) => {
    return /^[a-zA-Z\s]+$/.test(name);
  };

  const handleSendEmail = async () => {
    if (
      !nome ||
      !telefone ||
      !email ||
      !descricao ||
      !isValidEmail(email) ||
      !isValidPhone(telefone) ||
      !isValidName(nome)
    ) {
      setAttemptedSubmit(true);
      return;
    }

    setLoading(true);
    setError(false);
    setSuccess(false);

    try {
      const response = await axios.post(
        "https://sua-api-endpoint.com/contatos",
        {
          nome,
          telefone,
          email,
          descricao,
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        setNome("");
        setTelefone("");
        setEmail("");
        setDescricao("");
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContactsWrapper>
       <Helmet>
        <title>{t("Contact.title")} | {t("Contact.title_page")} </title>
        <meta name="description" content="Entre em contacto com a Médicos na Manga para teleconsultas e consultas ao domicílio em Portugal. Atendimento 24h." />
        <meta
          name="keywords"
          content="Contactos, email, telefone, médicos, telemedicina, consultas ao domicílio, Portugal"
        />
        <meta property="og:title" content="Contacte-nos | Médicos na Manga" />
        <meta property="og:url" content="https://medicosnamanga.pt/contactos" />
        <meta property="og:image" content="http://localhost:3000/static/media/logowb.1e1d4d66623c07078790.png" />
      </Helmet>
      <div className="contacts-container">
        <div className="left-container">
          <img src={contacts} alt="contacts-banner" />
        </div>

        <form className="right-container" onSubmit={(e) => e.preventDefault()}>
          <h1>{t("Contact.contact")}</h1>
          <div className="contacts-container">
            <ContactListWrapper />
          </div>
          <div className="social-container">
            <p>{t("Contact.follow")}</p>
            <SocialLinks />
          </div>
          <div className="email-container">
            <h2>{t("Contact.ask_info")}</h2>
            <div className="input-container">
              <input
                type="text"
                placeholder={t("Contact.placeholder_name")}
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                className={
                  (!nome || !isValidName(nome)) && attemptedSubmit
                    ? "error"
                    : ""
                }
              />
              {(!nome || !isValidName(nome)) && attemptedSubmit && (
                <p className="error-message">{t("Contact.placeholder_name_fail")}</p>
              )}
              <input
                type="phone"
                placeholder={t("Contact.placeholder_phone")}
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
                className={
                  (!telefone || !isValidPhone(telefone)) && attemptedSubmit
                    ? "error"
                    : ""
                }
              />
              {(!telefone || !isValidPhone(telefone)) && attemptedSubmit && (
                <p className="error-message">{t("Contact.placeholder_phone_fail")}</p>
              )}
            </div>
            <div className="input-container">
              <input
                type="email"
                placeholder={t("Contact.placeholder_mail")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={
                  (!email || !isValidEmail(email)) && attemptedSubmit
                    ? "error"
                    : ""
                }
              />
              {(!email || !isValidEmail(email)) && attemptedSubmit && (
                <p className="error-message">{t("Contact.placeholder_mail_fail")}</p>
              )}
            </div>
            <div className="textarea-container">
              <textarea
                id="textarea"
                cols="30"
                rows="7"
                placeholder={t("Contact.placeholder_textbox")}
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                className={!descricao && attemptedSubmit ? "error" : ""}
              />
            </div>
          </div>
          <div className="button-container">
            <Button onClick={handleSendEmail} disabled={loading}>
            {loading ? t("Contact.sending") : t("Contact.send")}
            </Button>
            {success && (
              <p className="success-message">{t("Contact.success")}</p>
            )}
            {error && (
              <p className="error-message">
               {t("Contact.fail")}
              </p>
            )}
          </div>
        </form>
      </div>
    </ContactsWrapper>
  );
};

export default Contacts;
