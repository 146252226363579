import { colors } from "_theme/colors";
import styled from "styled-components";

const Navbar = styled.div`
  position: sticky;
  top: 0%;
  z-index: 100;
  /* background: ${({ theme, opacity }) => theme.navbar_gradient(opacity)}; */
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.background_emphasis_header};

`;
export { Navbar };
