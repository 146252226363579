import { colors } from "_theme/colors";
import styled from "styled-components";

export const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  font-size: small;
  .button {
    background-color: ${colors.primary_2};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.primary_2};
    }
  }
`;

export const ModalContent = styled.div`
  background: ${({ theme }) => theme.background_emphasis_header};
  padding: 20px;
  width: 80%;
  max-width: 905px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

export const ModalHeader = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
  h2 {
    color: ${colors.primary_2};
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .cookie-section {
    display: flex;
    justify-content: space-between;

    .cookie-text-switch {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 0.5rem;
      max-width: 80%;
    }
    .active-wrapper {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      gap: 1rem;
      .always-active {
        white-space: nowrap;
        color: ${colors.primary_2};
      }
    }
    .text-button {
      gap: 1rem;
      display: flex;
      align-items: center;
    }
  }
  a {
    color: ${colors.primary_2};
    text-decoration: underline;
    font-weight: normal;
  }
`;

export const ModalText = styled.div`
  font-size: smaller;

  .bold {
    font-weight: bold;
  }

  a {
    color: ${colors.primary_2};
    text-decoration: underline;
    font-weight: normal;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
`;

export const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 700;
  &:hover {
    background-color: #0056b3;
  }
`;

export const Switch = styled.input.attrs({ type: "checkbox" })`
  appearance: none;
  min-width: 40px;
  width: 40px;
  height: 20px;
  background: #ccc;
  border-radius: 20px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background 0.3s;
  &.active {
    &:checked {
      background: #67b0ff;
    }
  }
  &:checked {
    background: #007bff;
  }

  &:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 50%;
    transition: transform 0.3s;
  }

  &:checked:before {
    transform: translateX(20px);
  }
`;
