import React from "react";
import { Helmet } from "react-helmet";

const GlobalJsonLd = () => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "MedicalOrganization",
    "name": "Médicos na Manga",
    "url": "https://medicosnamanga.pt/",
    "logo": "https://medicosnamanga.pt/static/media/logowb.png",
    "sameAs": [
      "https://www.facebook.com/medicosnamanga",
      "https://www.instagram.com/medicosnamanga",
      "https://www.linkedin.com/company/medicosnamanga",
      "https://x.com/MedicosNaManga"
    ],
    "description": "A Médicos na Manga oferece consultas médicas ao domicílio e teleconsultas em todo o Portugal, incluindo Açores e Madeira. Atendimento 24 horas por dia.",
    "areaServed": {
      "@type": "AdministrativeArea",
      "name": ["Portugal", "Açores", "Madeira"]
    },
    "address": {
      "@type": "PostalAddress",
      "addressCountry": "PT"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+351911140301",
      "contactType": "customer service",
      "areaServed": ["Portugal", "Açores", "Madeira"],
      "availableLanguage": [
        "Portuguese",
        "English",
        "Spanish",
        "French",
        "German",
        "Ukrainian"
      ]
    },
    "serviceType": "Telemedicina e Consultas ao Domicílio"
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
    </Helmet>
  );
};

export default GlobalJsonLd;
