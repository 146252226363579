import styled from "styled-components";
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";

const CarouselWrapper = styled.div`
  height: 100vh;
  margin-top: -83px;

  ${screens.lg} {
    height: 15rem;
    margin-top: 0;
  }

  .carouselContainer {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    position: absolute;
    display: flex;
    justify-content: center;
    overflow: hidden;
    ${screens.lg} {
      height: 15rem;
    }
  }

  .carouselImgs {
    width: 100%;
    height: 100%;
    animation: fade 1.5s ease-in-out;
    display: none;
    text-align: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    background-size: cover;

    ${screens.lg} {
      object-fit: contain;
      background-size: cover;
      background-position: left;
    }

    &.active {
      display: flex;
      justify-content: left;
      /* animation: zoomEffect 12s ease-in-out; */
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  @keyframes zoomEffect {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  .linkContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    z-index: 10;
    border-right: 0;
    border-bottom: 0;
    width: 100%;

    ${screens.lg} {
      border: none;
      justify-content: center;
    }
  }

  .shortcut {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1920px;
    width: 100vw;

    left: 0;
    bottom: 0;

    ${screens.lg} {
      display: none;
    }

    .calculator {
      width: 100%;
      height: 3rem;
      border-top: 2px solid ${colors.primary_light};
      h3 {
        color: ${colors.text};
      }

      .input-group {
        input {
          &::placeholder {
            color: ${colors.text};
          }
        }
        .form-control{
          border-bottom: 2px solid ${colors.text};
        }
      }
    }

    .link {
      display: flex;
      max-width: 1920px;
      width: 100%;

      a {
        display: flex;
        flex-grow: 1;
        justify-content: space-evenly;
        align-items: center;
        border-right: 2px solid ${colors.primary_light};
        padding: 1.5rem;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(10px);
        gap: 1rem;
        white-space: nowrap;

        &:last-child {
          border-right: none;
        }
        &:hover {
          background-color: ${colors.secondary};
          color: white;
        }

        &:active {
          background-color: ${colors.primary};
        }

        &:hover .arrow {
          transform: scale(1.3) translateX(0.5rem);
        }

        .text {
          color: ${colors.text};
          /* color: ${colors.white}; */
          font-weight: 800;
          /* text-shadow: 0px 0px 2px white; */

          p {
            color: ${colors.text};
          }
        }

        .arrow {
          display: flex;
          align-items: center;
          height: 100%;
          align-self: flex-end;
          transform: translate(0);
          transition: all 0.2s ease;
        }
      }
    }
  }
`;

const SlideContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1920px;
  height: 100%;
  position: absolute;

  ${screens.lg} {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }

  .slide-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 2rem;
    height: 30%;
    width: 50vw;

    ${screens.lg} {
      margin: 1rem;
      height: 12rem;
      width: 100%;
      justify-content: flex-end;
    }

    .slide-text {
      text-align: left;
      padding: 0.5rem 1rem;
      margin-bottom: 1rem;

      ${screens.lg} {
        margin: 0;
      }

      h1,
      p {
        color: ${colors.text};
      }
      h1 {
        max-width: 700px;
        font-size: 48px;
        animation: fadeText 0.5s ease-in-out;

        /* text-transform: uppercase; */

        ${screens.lg} {
          font-size: 20px;
        }
      }
      @keyframes fadeText {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
    button {
      margin: 1rem;
      background-color: ${colors.primary};
      color: ${colors.white};

      &:hover {
        background-color: ${colors.primary_2};
      }

      ${screens.lg} {
        font-size: x-small;
        margin: 1rem 1rem;
      }
    }
  }
`;

export { CarouselWrapper, SlideContainer };
