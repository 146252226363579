import React, { useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  DicasMenuContainer,
  DicasMenuWrapper,
  MenuItem,
} from "./dicas-menu.style";
import AOS from "aos";
import "aos/dist/aos.css";

const DicasMenu = () => {
  const { t } = useTranslation();
  const iframeRef = useRef(null);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const iframe = iframeRef.current;
            if (iframe) {
              const src = iframe.getAttribute("data-src");
              iframe.setAttribute("src", src);
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    if (iframeRef.current) {
      observer.observe(iframeRef.current);
    }

    return () => {
      if (iframeRef.current) {
        observer.unobserve(iframeRef.current);
      }
    };
  }, []);

  return (
    <DicasMenuWrapper>
      <DicasMenuContainer>
        <div className="title">
          <h3>{t("Dicas.title")}</h3>
        </div>
        <div className="top-container">
          <Link className="top-right" data-aos="fade-left" data-aos-delay="200">
            <iframe
              ref={iframeRef}
              data-src="https://www.youtube.com/embed/y4TJulP0izM?rel=0&loop=1&playlist=y4TJulP0izM&autoplay=1&controls=0&mute=1" // Adicionado &mute=1
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </Link>
          <div className="top-left">
            <Link
              className="media-1"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              <iframe
                src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0Wri2RVzC6RTNmXUsYMAS1s9jxyfi1CXjjAF8FLiWS7xEsq4hVRzVcx2KbowwH4gTl%26id%3D100091664635752&show_text=false&width=500"
                style={{ border: "none", overflow: "hidden" }}
                // scrolling="no"
                // frameBorder="0"
                loading="lazy"
                // allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>
            </Link>
            <div className="media-2" data-aos="fade-right" data-aos-delay="400">
              <div className="text-area">
                <p>
                  {t("Dicas.text_1")}
                  <span>{t("Dicas.span")}</span>
                  {t("Dicas.text_2")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-container">
          <div className="frame" data-aos="fade-right" data-aos-delay="600">
            <iframe
              src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0FS4PvUqYjGaoha41ETaKDB245oRWZVJVgCjHV8cJ4y6tG5akSwD83WN484qyLaGsl%26id%3D100091664635752&show_text=false"
              // scrolling="no"
              loading="lazy"
              frameborder="0"
              // allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
          <div className="frame" data-aos="fade-right" data-aos-delay="400">
            <iframe
              src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0VXpooHw4CXkDTAXfQL8Em8MtKK7cF7R4PeYCAM3q9yNxwSU4q3DwNHNQJH1nP3Ffl%26id%3D100091664635752&show_text=false"
              // scrolling="no"
              loading="lazy"
              frameborder="0"
              // allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
          <div className="frame" data-aos="fade-right" data-aos-delay="200">
            <iframe
              src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0xGAtf8WZVifEkSNRgFC6UYibq4VVMysLWLqxD8nRLGvvX7EzX5ARWBdSTWxfBErCl%26id%3D100091664635752&show_text=false&width=500"
              // scrolling="no"
              loading="lazy"
              frameborder="0"
              // allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
      </DicasMenuContainer>
    </DicasMenuWrapper>
  );
};

export default DicasMenu;
