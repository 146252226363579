import { screens } from "_theme/screens";
import styled from "styled-components";

const HomeContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* gap: 4.5rem; */

  
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
  max-width: 1920px;
  margin: 0 auto;
  /* margin: 1.5rem auto; */
  ${screens.lg} {
    gap: 2.5rem;
  }
`;

const CalcContainer = styled.div`
display: none;
 ${screens.lg} {
    display: block;
  }
`;

export { HomeContainer, Container, CalcContainer };
