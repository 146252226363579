import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ServicesWrapper } from "./services.style";
import home from "_assets/img/graphics/servicos-home.svg";
import online from "_assets/img/graphics/servicos-online.svg";
import person from "_assets/img/graphics/servicos-person.svg";

import { Link } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

const Services = () => {
  const { t } = useTranslation();
  const importAll = (r) => r.keys().map(r);
  const serviceImages = importAll(
    require.context("_assets/img/servicos/", false, /\.svg$/)
  );
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <ServicesWrapper>
      <Helmet>
        <meta name="description" content="Especialidades disponiveis" />
        <meta
          name="keywords"
          content="urgência médica, especialidades,Terapia da Fala, Clínica Geral, Pediatria, Psicologia, Cuidados Respiratórios, Fisioterapia, Ortopedia, Nutrição, Psiquiatria, Medicina do trabalho,Medicina do viajante,Enfermagem"
        />
        <title>{t("Services.title")} | {t("Services.title_page")} </title>
      </Helmet>
      <div className="services-banner">
        <h1>{t("Services.title")}</h1>
      </div>

      <div className="info-container">
        <figure className="item">
          <img src={home} alt="24Hours" />
          <figcaption>{t("Services.home")}</figcaption>
        </figure>

        <figure className="item">
          <img src={online} alt="Personal Online service" />
          <figcaption>{t("Services.online")}</figcaption>
        </figure>

        <figure className="item">
          <img src={person} alt="Personal followup" />
          <figcaption>
            {t("Services.person")}
            <br />
            <p>{t("Services.person_1")}</p>
          </figcaption>
        </figure>
      </div>
      <div className="service-container">
        <div className="grid">
          {serviceImages.map((img, index) => (
            <Link
              rel="stylesheet"
              target="_blank"
              to="https://app.medicosnamanga.pt/servicos/pedidoconsulta"
            >
              <figure data-aos="fade-up" key={index} className="grid-item">
                {/* <figure data-aos="fade-up" data-aos-delay={`${0 + 50 * index}`} key={index} className="grid-item"> */}
                <img src={img} alt={`Service ${index}`} />
                <figcaption>
                  <p>{t(`Service.c_${index}`)}</p>
                </figcaption>
              </figure>
            </Link>
          ))}
        </div>
      </div>
    </ServicesWrapper>
  );
};

export default Services;
