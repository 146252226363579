import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CalculatorContainer, CalculatorWrapper } from "./calculator.style";
import { Button } from "_components/atoms";

const Calculator = () => {
  const { t, i18n } = useTranslation();
  const [postalCode, setPostalCode] = useState("");
  const [placeholder, setPlaceholder] = useState(t("Calculator.postcode"));
  const navigate = useNavigate();

  useEffect(() => {
    setPlaceholder(t("Calculator.postcode"));
  }, [i18n.language, t]);

  const handleCalculate = () => {
    navigate("/calcular-consulta", { state: { postalCode } });
  };

  const formatPostalCode = (value) => {
    // Remove caracteres que não sejam números e hífen
    let numbers = value.replace(/[^\d-]/g, "");

    // Remove hífen se não estiver na 5ª posição ou se houver mais de um hífen
    if (numbers.includes('-')) {
      numbers = numbers.replace(/-/g, '');
    }

    // Trunca os números para caber no formato 9999-999
    numbers = numbers.slice(0, 7);

    // Adiciona o hífen na 5ª posição, se não houver
    if (numbers.length > 4) {
      return `${numbers.slice(0, 4)}-${numbers.slice(4)}`;
    }

    return numbers;
  };

  const handlePostalCodeChange = (event) => {
    const formattedPostalCode = formatPostalCode(event.target.value);
    setPostalCode(formattedPostalCode);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleCalculate();
    }
  };

  const handleFocus = () => {
    setPlaceholder("4100-379");
  };

  const handleBlur = () => {
    setPlaceholder(t("Calculator.postcode"));
  };

  return (
    <CalculatorWrapper>
      <CalculatorContainer>
        <h3>{t("Calculator.title_page")}</h3>
        <div className="input-group">
          <input
            className="form-control"
            type="text"
            placeholder={placeholder} // Placeholder dinâmico
            value={postalCode}
            onChange={handlePostalCodeChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus} // Alterna o placeholder ao focar
            onBlur={handleBlur} // Alterna o placeholder ao desfocar
          />
          <Button preset={"primary"} onClick={handleCalculate}>
            {t("Calculator.calculate")}
          </Button>
        </div>
      </CalculatorContainer>
    </CalculatorWrapper>
  );
};

export default Calculator;