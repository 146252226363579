import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ThemeContext } from "_context/themeContext";
import {
  Button,
  LanguageSelector,
  Logo,
  Menu,
  SocialLinks,
  Switch,
} from "_components/atoms";
import Burger from "../burger/burger";
import { NavWrapper } from "./nav.style";
import { ReactComponent as Login } from "_assets/img/icons/login.svg";

const Nav = () => {
  const { isDarkTheme, toggleTheme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <NavWrapper isScrolled={isScrolled}>
      <Logo isScrolled={isScrolled} />

      <div className="menu">
        <div className="menu-upper">
          <Link to="tel:911 140 301">
            <p>911 140 301 ({t("Nav.phone")}) </p>
          </Link>
          <span>|</span>
          <a href="geral@medicosnamanga.pt"
            className="email"
            onClick={() =>
              (window.location =
                "mailto:geral@medicosnamanga.pt?subject=Pedido de Informação&body=")
            }
          >
            <p>geral@medicosnamanga.pt</p>
          </a>
          <SocialLinks />
          <LanguageSelector />
        </div>
        <div className="menu-lower">
          <Menu />
          <div className="login">
            <Link to="/login">
              <Button>{t("Nav.login")}</Button>
            </Link>
          </div>
          <div className="make-appointment">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://app.medicosnamanga.pt/servicos/pedidoconsulta"
            >
              <Button>{t("Nav.make-appointment")}</Button>
            </Link>
          </div>
          {/* <div className="lang">
            <LanguageSelector />
          </div> */}

          <div className="switch">
            <Switch />
          </div>
        </div>{" "}
        <div className="burger">
          <Burger />
        </div>
      </div>
    </NavWrapper>
  );
};

export default Nav;
