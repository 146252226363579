import { colors } from '_theme/colors';
import styled from 'styled-components';

export const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: ${({ theme }) => theme.background_emphasis_header};
  padding: 20px;
  width: 80%;
  max-width: 905px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

export const ModalBody = styled.div`
display: flex;
justify-content: space-between;
align-items: center;

`;

export const ModalText = styled.div`
font-size: smaller;

.bold{
    font-weight: bold;
}

a{
    color: ${colors.primary_2};
    text-decoration: underline;
    font-weight: normal;
}
`;

export const ModalButton = styled.div`

max-width: 167px;
width: 100%;
`;


export const Button = styled.button`
max-width: 167px;
width: 100%;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background-color: #0056b3;
  }
`;