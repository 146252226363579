import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageOption, LanguageToggleWrapper } from "./language-toogle.style";
import { useLanguage } from "_context/languageContext";

const LanguageToggle = ({ closeBurgerMenu }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { language, changeLanguage } = useLanguage(); // Use o hook useLanguage
  const { i18n } = useTranslation();

  // List of supported languages
  const languages = ["pt", "en", "fr", "de", "es", "ua"];

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
    if (closeBurgerMenu) {
      closeBurgerMenu();
    }
  };

  return (
    <LanguageToggleWrapper>
      {languages.map((lang, index) => (
        <React.Fragment key={lang}>
          <LanguageOption
            onClick={() => handleLanguageChange(lang)}
            active={i18n.language === lang}
          >
            {lang.toUpperCase()}
          </LanguageOption>
          {index < languages.length - 1 && <span>|</span>}
        </React.Fragment>
      ))}
    </LanguageToggleWrapper>
  );
};

export default LanguageToggle;