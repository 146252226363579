import React from "react";
import { TermsWrapper } from "./terms.style";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation();
  return (
    <TermsWrapper>
      <Helmet>
        <meta name="description" content="Your meta description here" />
        <title>{t("Helmet.title")} | {t("Terms.pageTitle")}</title>
      </Helmet>
      <div className="terms-banner"></div>
      <div className="terms-container">
        <h1>{t("Terms.header")}</h1>

        <div className="terms">
          <ol>
            <h2>{t("Terms.section1Title")}</h2>
            <ul>
              <li className="no-bullet">{t("Terms.section1Content")}</li>
            </ul>

            <li className="capter">
              <h2>{t("Terms.chapter1Title")}</h2>
              <ul>
                <li>{t("Terms.chapter1Def")}</li>
              </ul>
              <ul>
                <h3>{t("Terms.dataPersonal")}</h3>
                <li>{t("Terms.dataPersonalDesc")}</li>
                <h3>{t("Terms.treatment")}</h3>
                <li>{t("Terms.treatmentDesc")}</li>
                <h3>{t("Terms.file")}</h3>
                <li>{t("Terms.fileDesc")}</li>
                <h3>{t("Terms.controller")}</h3>
                <li>
                  {t("Terms.controllerDesc")}
                  <a href="mailto:data@medicosnamanga.pt">
                    data@medicosnamanga.pt
                  </a>
                </li>
                <h3>{t("Terms.outsourcing")}</h3>
                <li>{t("Terms.outsourcingDesc")}</li>
                <h3 id="consent">{t("Terms.consent")}</h3>
                <li>{t("Terms.consentDesc")}</li>
                <h3>{t("Terms.geneticData")}</h3>
                <li>{t("Terms.geneticDataDesc")}</li>
                <li>{t("Terms.geneticDataDesc2")}</li>
                <h3>{t("Terms.biometricData")}</h3>
                <li>{t("Terms.biometricDataDesc")}</li>
                <h3> {t("Terms.healthData")}</h3>
                <li>{t("Terms.healthDataDesc")}</li>
                <li>{t("Terms.healthDataDesc2")}</li>
                <h3>{t("Terms.dataTreatment")}</h3>
                <li>
                  {t("Terms.dataTreatmentDesc")}
                  <ul className="custom-bullet">
                    <li>{t("Terms.dataTreatmentDesc1")}</li>
                    <li>{t("Terms.dataTreatmentDesc2")}</li>
                    <li>{t("Terms.dataTreatmentDesc3")}</li>
                    <li>{t("Terms.dataTreatmentDesc4")}</li>
                    <li>{t("Terms.dataTreatmentDesc5")}</li>
                    <li>{t("Terms.dataTreatmentDesc6")}</li>
                  </ul>
                </li>
                <h3> {t("Terms.under13")}</h3>
                <li>{t("Terms.under13Desc")}</li>
                <h3>{t("Terms.dataOwner")}</h3>
                <li>
                  {t("Terms.dataOwnerDesc")}
                  <ol className="rights-list">
                    <li>
                      {t("Terms.dataOwnerDesc1")}
                      <a href="mailto:data@medicosnamanga.pt">
                        data@medicosnamanga.pt
                      </a>
                      )
                    </li>
                    <li>
                      {t("Terms.dataOwnerDesc2")}
                      <a href="mailto:data@medicosnamanga.pt">
                        data@medicosnamanga.pt
                      </a>
                    </li>
                    <li>
                      {t("Terms.dataOwnerDesc3")}
                      <a href="mailto:data@medicosnamanga.pt">
                        data@medicosnamanga.pt
                      </a>
                    </li>
                    <li>
                      {t("Terms.dataOwnerDesc4")}
                      <a href="mailto:data@medicosnamanga.pt">
                        data@medicosnamanga.pt
                      </a>
                    </li>
                    <li>
                      {t("Terms.dataOwnerDesc5")}
                      <a href="mailto:data@medicosnamanga.pt">
                        data@medicosnamanga.pt
                      </a>
                    </li>
                    <li>
                      {t("Terms.dataOwnerDesc6")}
                      <a href="mailto:data@medicosnamanga.pt">
                        data@medicosnamanga.pt
                      </a>
                    </li>
                    <li>
                      {t("Terms.dataOwnerDesc7")}
                      <a href="mailto:data@medicosnamanga.pt">
                        data@medicosnamanga.pt
                      </a>
                    </li>
                  </ol>
                  <h3> {t("Terms.controlAuth")}</h3>
                  <li>
                    {t("Terms.controlAuthDesc")}{" "}
                    <a
                      href="https://www.cnpd.pt/"
                      target="https://www.cnpd.pt/"
                    >
                      https://www.cnpd.pt/
                    </a>
                  </li>
                </li>
              </ul>
            </li>
            <li className="capter">
              <h2>{t("Terms.chapter2Title")}</h2>
              <h3 className="no-list">{t("Terms.partA")}</h3>
              <ul>
                <li className="no-bullet">
                  {t("Terms.partADesc")}
                  <ul>
                    <li>
                      <span>{t("Terms.partADescSpan1")}</span>
                      {t("Terms.partADesc1")}
                    </li>
                    <li>
                      <span>{t("Terms.partADescSpan2")}</span>
                      {t("Terms.partADesc2")}
                    </li>
                    <li>
                      <span>{t("Terms.partADescSpan3")}</span>
                      {t("Terms.partADesc3")}
                    </li>
                    <li>
                      <span>{t("Terms.partADescSpan4")}</span>
                    {t("Terms.partADesc4")}  <a href="#consent"> F) a I);</a>
                    </li>
                    <li>
                      <span>{t("Terms.partADescSpan5")}</span>
                      {t("Terms.partADesc5")}
                    </li>
                    <li>
                      <span>{t("Terms.partADescSpan6")}</span>
                      {t("Terms.partADesc6")}
                    </li>
                    <li>
                      <span>{t("Terms.partADescSpan7")}</span>
                      {t("Terms.partADesc7")}
                    </li>
                  </ul>
                </li>
              </ul>
              <h3 className="no-list">{t("Terms.partB")}</h3>
              <ul>
                <li className="no-bullet">
                  <p>{t("Terms.partBDesc")}</p>
                </li>
                <ol className="rights-list">
                  <li>{t("Terms.partBDesc1")}</li>
                  <li>{t("Terms.partBDesc2")}</li>
                  <li>{t("Terms.partBDesc3")}</li>
                  <li>{t("Terms.partBDesc4")}</li>
                </ol>
              </ul>
              <h3 className="no-list">{t("Terms.partC")}</h3>
              <ul>
                <li className="no-bullet">
                  <p>{t("Terms.partCDesc")}</p>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </TermsWrapper>
  );
};

export default Terms;
