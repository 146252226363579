import styled from "styled-components";
import terms from "_assets/img/banners/BANNER-Homepage-02.png";
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";

const TermsWrapper = styled.div`
  .terms-banner {
    background-image: url(${terms});
    width: 100%;
    height: 9rem;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
  }

  .terms-container {
    list-style-position: inside;
    text-align: justify;
    margin: 5rem auto 3rem;
    max-width: 750px;
    ${screens.md} {
      margin: 3rem 2rem;
    }
  }

  .terms {
    list-style-position: inside;
    line-height: 1.5;

    a{
      color: ${colors.primary_2};
      &:hover{
        text-decoration: underline;
      }
    }

    ol {
      counter-reset: item;
      padding: 0;
    }

    ol > li {
      counter-increment: item;
      margin: 1rem 0;
    }

    ol > li::marker {
      font-size: x-large;
      color: ${colors.primary_2};
      font-weight: 700;
    }

    ol ul > li::before {
      display: block;
    }

    ol ol > li:before {
      font-weight: 700;
    }

    ol > li h2 {
      display: inline; /* This makes the h2 inline with the ::before content */
    }

    ul > li {
      margin: 1rem 0;
      list-style-type: disc; /* Define bullets in the form of a disc */
      list-style-position: inside; /* Ensure markers are inside */
    }
    ul > li::marker {
      color: ${({ theme }) => theme.text};
    }

    .no-bullet {
      list-style-type: none;  // Remove o bullet do item específico
      text-indent: 3rem;
    }

    .custom-bullet li {
      list-style: none; /* Remove o estilo de lista padrão */
      position: relative;
      padding-left: 20px; /* Espaço para o bullet personalizado */
    }

    .custom-bullet li:before {
      content: "-"; /* Caractere usado como bullet */
      font-size: larger;
      position: absolute;
      left: 0; /* Alinha o bullet à esquerda */
      color: ${({ theme }) => theme.text};
    }

    .rights-list {
      list-style-type: none; // Remove qualquer estilo de lista
      padding-left: 20px; // Espaço para indentação
      counter-reset: section 0; // Reseta o contador especificamente para esta lista
    }

    .rights-list > li::before {
      content: counter(section) ".º – "; // Números com "º –" antes do texto
      counter-reset: initial;
      counter-increment: section; // Incrementa o contador para cada item
      color: ${({ theme }) => theme.text};
      font-weight: bold; // Texto em negrito para os números
    }
    .capter {
      margin-top: 2rem;
    }
  }

  h1 {
    color: ${colors.text_muted};
    font-size: 2.5rem;
    text-align: start;
  }

  h2 {
    color: ${colors.primary_2};
    margin-top: 3rem;
  }

  h3 {
    color: ${colors.primary_2};
    text-align: start;
    counter-increment: section;
    display: block; /* Make h3 a block element to appear on a new line */
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 2rem;
    &.no-list{
      text-align: center;
    }
  }

  h3:before {
    content: counter(section, upper-alpha) ") ";
  }

  .no-list:before {
    content: none; // Remove o conteúdo do pseudo-elemento ::before
  }

  h1,
  h2 {
    text-transform: uppercase;
    font-weight: 700;
  }

  span{
    text-decoration: underline;
    font-weight: 700;
  }
`;

export { TermsWrapper };
