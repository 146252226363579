import { colors } from "_theme/colors";
import styled from "styled-components";

const UserLayoutWrapper = styled.div`
  background: ${({ theme }) => theme.background};
  contain: paint;
  position: relative;
  /* height: 100%; */
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
 `

export{UserLayoutWrapper}