import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled from "styled-components";

const CopyrightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  font-size: 14px;
  padding: 0.5rem 2rem;
  width: 100%;

  ${screens.lg} {
    flex-direction: column-reverse;
    font-size: x-small;
  }

  .production {
    display: flex;
    gap: 1rem;
    align-items: center;

    ${screens.lg} {
      /* justify-content: space-between; */
    }

    .ers {
      display: flex;
      align-items: center;
      gap: 5px;
      .ers-logo {
        width: 6rem;
      }

      a {
:hover {
              color: ${colors.primary};
            }
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
      }
    }

    span {
       
      ${screens.lg} {
        text-align: end;
      }
      ${screens.md} {
        text-align: center;
      }
    }
  }

  .navigation {
    gap: 1rem;
    display: flex;
    justify-content: space-between;
     :hover {
            color: ${colors.primary};
          }

    .app-button {
      display: none;
      ${screens.lg} {
        margin-top: 1rem;
        display: block;
      }
      ${screens.md} {
        display: none;
      }
    }
    .app-button-mobile {
      display: none;
      ${screens.md} {
        display: block;
      }
      ${screens.ssm} {
        display: none;
      }
    }
  }
  .menu-button {
    display: flex;
  }
  .menu {
    gap: 1rem;
    display: flex;

    ${screens.md} {
      flex-direction: column;
      font-weight: bold;
    }
  }
  .app-livro-mobile {
    display: none;
    ${screens.lg} {
      display: block;
    }
  }

  .social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;
    ${screens.md} {
      display: block;
    }
  }
`;
export { CopyrightWrapper };
