import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled from "styled-components";

export const DicasMenuWrapper = styled.section`
  margin: 0 auto;
  margin-bottom: 2rem;
`;

export const DicasMenuContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 2rem;
  margin: 0 auto;
  max-width: 1230px;
  width: 100%;

  ${screens.xl} {
    grid-column-gap: 0;
  }

  .title {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    grid-column: 1 / 13;
    margin-bottom: 64px;
    max-width: 615px;
    font-size: xx-large;
    h3 {
      text-align: center;
      color: ${colors.primary_2};
      text-transform: uppercase;
    }

    ${screens.md} {
      margin: 0 auto;
      h3 {
        font-size: x-large;
        padding: 0 2rem;
      }
    }
  }

  .top-container {
    position: relative;
    grid-column: 2 / 12;
    padding-top: 4rem;
    ${screens.lg} {
      grid-column: 2 / 12;
    }
    ${screens.sm} {
      grid-column: 3 / 11;
    }

    .top-left {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 2rem;
      padding-top: 2.5rem;

      ${screens.sm} {
        padding-top: 20.5rem;
        grid-column-gap: 2rem;
      }

      .media-1 {
        width: 92%;
        grid-column: 1 / 6;
        left: 0;
        max-width: 352px;
        max-height: 352px;
        position: absolute;
        ${screens.lg} {
          max-width: 225px;
        }
        ${screens.sm} {
          margin-top: 25rem;
          max-height: 225px;
        }

        iframe {
          width: 100%;
          height: 352px;
          border-radius: 10px;
          &:hover {
            border-radius: 0;
          }
          ${screens.sm} {
            max-height: 225px;
          }
        }
      }

      .media-2 {
        z-index: 3;
        margin-top: 5rem;
        clip-path: polygon(
          0 0,
          100% 0,
          100% calc(100% - 110px),
          calc(100% - 110px) 100%,
          0 100%
        );
        grid-column: 5 / 8;
        height: 408px;
        width: 408px;
        margin-top: 88px;
        padding: 72px;
        background-color: ${colors.primary_2};

        p {
          font-size: 2rem;
          color: white;
          span {
            color: white;
            font-weight: 600;
          }
        }

        ${screens.lg} {
          height: 300px;
          width: 300px;
          clip-path: polygon(
            0 0,
            100% 0,
            100% calc(100% - 85px),
            calc(100% - 85px) 100%,
            0 100%
          );
          grid-column: 4 / 9;
          text-align: justify;
          p {
            font-size: large;
          }
        }
        ${screens.sm} {
          grid-column: 2 / 7;
          margin-bottom: 15rem;
          margin-top: 8rem;
        }
      }
    }

    .top-right {
      position: absolute;
      max-width: 100%;
      right: 0;
      top: 0;
      width: 34%;
      height: 100%;
      padding-top: 32px;

      ${screens.xl} {
        /* width: auto; */
      }
      ${screens.md} {
        /* max-width: 635px; */
        right: 0;
        top: 0;
        text-align: center;
        max-height: 533px;
      }
      ${screens.sm} {
        width: 100%;
        text-align: center;
        /* max-height: 500px; */
      }

      iframe {
        /* height: 743px; */
        height: 100%;
        width: 100%;

        ${screens.xl} {
          height: 100%;
          /* width: auto; */
        }
      }
    }
  }

  .bottom-container {
    display: flex;
    grid-column: 2 / 12;
    text-align: center;
    grid-column-gap: 1rem;
    margin-top: 64px;
    max-width: 100%;
    /* border: 1px solid black; */
   
    .frame{
 width: 100%;
    height: 300px;
    ${screens.xl} {
      height: 200px;
    }
    ${screens.lg} {
      grid-column: 1 / 13;
    }
    ${screens.sm} {
      display: none;
    }
      iframe {
      border-radius: 10px;
      &:hover {
        border-radius: 0;
      }
      height: 100%;
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
      ${screens.xl} {
        width: 200px;
      }
      ${screens.lg} {
        /* width: 100px; */
      }
      ${screens.sm} {
        width: 100px;
      }
    }
    }
    
  }
`;
