import React, { useEffect, useState } from "react";
import { CarouselWrapper, SlideContainer } from "./carousel.style";
import { Button } from "_components/atoms";
import { Link } from "react-router-dom";

// import bannerImage1 from "_assets/img/carousel/desktop/1.jpg";
// import bannerImage2 from "_assets/img/carousel/desktop/2.jpg";
// import bannerImage3 from "_assets/img/carousel/desktop/3.jpg";
// import bannerImage4 from "_assets/img/carousel/desktop/4.jpg";
import bannerImage1 from "_assets/img/carousel/1.webp";
import bannerImage2 from "_assets/img/carousel/2.jpg";
import bannerImage3 from "_assets/img/carousel/3.jpg";
import bannerImage4 from "_assets/img/carousel/4.jpg";
import bannerImage5 from "_assets/img/carousel/5.jpg";
// import bannerImage6 from "_assets/img/carousel/6.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Calculator from "../calculator/calculator";
import { useTranslation } from "react-i18next";

const Carousel = () => {
  const { t } = useTranslation();

  const phrases = [
    t("Carousel.phrase_1"),
    t("Carousel.phrase_2"),
    t("Carousel.phrase_3"),
    t("Carousel.phrase_4"),
    t("Carousel.phrase_5"),
    t("Carousel.phrase_6"),
    t("Carousel.phrase_7"),
    t("Carousel.phrase_8"),
  ];
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentPhrase, setCurrentPhrase] = useState(0);

  const slides = [
    { id: 0, img: bannerImage1 },
    { id: 1, img: bannerImage2 },
    { id: 2, img: bannerImage3 },
    { id: 3, img: bannerImage4 },
    { id: 4, img: bannerImage5 },
    // { id: 5, img: bannerImage6 },
  ];

  function showSlide(slideIndex) {
    if (slideIndex >= slides.length) {
      setCurrentSlide(0);
      setCurrentPhrase(0);
    } else if (slideIndex < 0) {
      setCurrentSlide(slides.length - 1);
      setCurrentPhrase(0);
    } else {
      setCurrentSlide(slideIndex);
      setCurrentPhrase(0);
    }
  }

  function nextSlide() {
    showSlide(currentSlide + 1);
  }

  function previousSlide() {
    showSlide(currentSlide - 1);
  }

  useEffect(() => {
    const phraseInterval = setInterval(() => {
      setCurrentPhrase((current) => (current + 1) % phrases.length);
    }, 4000);
    return () => clearInterval(phraseInterval);
  }, []);

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 12000);
    return () => clearInterval(slideInterval);
  }, [currentSlide]);

  return (
    <CarouselWrapper>
      <div className="carouselContainer">
        {slides.map((slide, index) => (
          <div
            loading="lazy"
            key={slide.id}
            className={`carouselImgs slide${slide.id} ${
              currentSlide === slide.id ? "active" : "hidden"
            }`}
            style={{ backgroundImage: `url(${slide.img})` }}
          ></div>
        ))}
        <SlideContainer>
          <div className="slide-info">
            <div className="slide-text">
              <h1 key={currentPhrase}>{phrases[currentPhrase]}</h1>
            </div>

            <a
              href="https://app.medicosnamanga.pt/servicos/pedidoconsulta"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="button">{t("Carousel.button")}</Button>
            </a>
          </div>
        </SlideContainer>
        <div className="linkContainer">
          <div className="shortcut">
            <div className="link">
              <Link
                to="https://app.medicosnamanga.pt/servicos/pedidoconsulta"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="text">
                  <p>{t("Carousel.link_1")}</p>
                </div>
                <div className="arrow">
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </Link>

              <Link
                to="https://app.medicosnamanga.pt/servicos/acompanhamento"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="text">
                  <p>{t("Carousel.link_2")}</p>
                </div>
                <div className="arrow">
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </Link>

              <Link
                to="https://app.medicosnamanga.pt/servicos/medico24h"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="text">
                  <p>{t("Carousel.link_3")}</p>
                </div>

                <div className="arrow">
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </Link>

              <Link
                to="https://app.medicosnamanga.pt/servicos/socios"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="text">
                  <p>{t("Carousel.link_4")}</p>
                </div>
                <div className="arrow">
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </Link>
            </div>
            <div className="calculator">
              <Calculator />
            </div>
          </div>
          {/* <div className="dotContainer">
            <div className="arrow prev" onClick={previousSlide}></div>
            {slides.map((slide) => (
              <span
                key={slide.id}
                className={`dot ${currentSlide === slide.id ? "active" : ""}`}
                onClick={() => showSlide(slide.id)}
              ></span>
            ))}
            <div className="arrow next" onClick={nextSlide}></div>
          </div> */}
        </div>
      </div>
    </CarouselWrapper>
  );
};

export default Carousel;
