// top-page.style.js
import { colors } from "_theme/colors";
import { screens } from "_theme/screens";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    transform: translateX(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

export const TopButton = styled.button`
  background: ${colors.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 2.5rem;
  right: 0;
  top: 50vh;
  z-index: 80;
  border: none;
  position: absolute; //para funcionar em ios e android da nas 2 opções absolute e fixed

  cursor: pointer;
  animation: ${fadeIn} 0.5s ease-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  transform: ${props => props.show ? 'translateX(0)' : 'translateX(20px)'};
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;

  &:hover .top-arrow {
    animation: ${bounce} 1s infinite;
  }

   ${screens.lg} {
       height:3rem;
       width: 2rem;
       top: 80vh;
      
       .top-arrow{
        animation: ${bounce} 1s infinite;
       }
      }

  .top-arrow {
    text-align: center;
    height: 4rem;
    width: 2rem;
    ${screens.lg} {
       height: 2rem;

      
      }
    svg {
      width: 100%;
      height: 100%;
    }
  }

  /* &:before {
    content: '⬆️';
    font-size: 48px;
  } */
`;
