import React, { useContext } from "react";
import { Link } from "react-router-dom";

import Button from "../buttons/button/button";
import LanguageToggle from "../language/language-toogle/language-toogle";
import { useTranslation } from "react-i18next";
import { Overlay, StyledMenu } from "./burger-menu.styles";
import Switch from "../switch/switch";
import { ThemeContext } from "_context/themeContext";
import lightLogo from "_assets/img/logos/logo-white-vertical.png";
import darkLogo from "_assets/img/logos/logo-black-vertical.png";

const BurgerMenu = (props) => {
  const navigation = [
    { name: "Nav.about", href: "/about" },
    { name: "Nav.services", href: "/services" },
    // { name: "Nav.staff", href: "/staff" },
    { name: "Nav.contacts", href: "/contacts" },
  ];

  const { open, setOpen } = props;
  const { t } = useTranslation();
  const handleCloseMenu = () => {
    setOpen(false);
  };
  const { isDarkTheme, toggleTheme } = useContext(ThemeContext);

  return (
    <>
      {open && <Overlay onClick={handleCloseMenu} />}{" "}
      {/* Sobreposição para clicar fora do menu */}
      <StyledMenu open={open}>
        {navigation.map((item) => (
          <Link
            className="linkstyle"
            onClick={handleCloseMenu} // Fecha o menu ao clicar em um item
            key={item.name}
            to={item.href}
          >
            {t(item.name)}
          </Link>
        ))}
        <Switch onClick={handleCloseMenu} />
        <div className="menu-button">
          <Button>
            <Link to="/login" onClick={handleCloseMenu}>
              {t("Nav.login")}
            </Link>
          </Button>
        </div>
        <div className="logo-container">
          <Link className="logo" to="/teste">
            <img
              src={isDarkTheme ? lightLogo : darkLogo}
              alt={isDarkTheme ? "Dark Mode" : "Light Mode"}
            />
          </Link>
        </div>
        <LanguageToggle closeBurgerMenu={handleCloseMenu} />
      </StyledMenu>
    </>
  );
};

export default BurgerMenu;