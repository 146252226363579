import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { CalcContainer, Container, HomeContainer } from "./home.style";
import Carousel from "_components/molecules/carousel/carousel";
import About from "_pages/user/about/about";

import {
  Calculator,
  DicasMenu,
} from "_components/molecules";

const Welcome = () => {
  const { t } = useTranslation();
  return (
    <HomeContainer>
      
      <Carousel />
      <CalcContainer>
        <Calculator />
      </CalcContainer>
      <Container>
        <About />
        <DicasMenu />
      </Container>
    </HomeContainer>
  );
};

export default Welcome;
