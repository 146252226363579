// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(initReactI18next) // passa o i18n para o react-i18next
  .use(HttpApi) // para carregar as traduções
  // .use(LanguageDetector) // detecta o idioma do navegador
  .init({
    supportedLngs: ["pt", "en", "fr", "de", "es", "ua"],
    fallbackLng: "pt",
    detection: {
      order: ["localStorage", "path", "cookie", "htmlTag", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "lang/locales/{{lng}}/translation.json",
    },
    react: {
      useSuspense: true, // Configuração para o Suspense
    },
  });

export default i18n;
